import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        {/* <path 
          d="M 8.438 19.688 L 5.625 19.688 L 5.625 0 L 0 0 L 0 19.688 L 2.813 19.688 L 2.813 25.313 L 8.438 25.313 L 8.438 19.688 Z M 16.875 25.313 L 11.25 25.313 L 11.25 19.688 L 14.063 19.688 L 14.063 0 L 19.688 0 L 19.688 19.688 L 16.875 19.688 L 16.875 25.313 Z M 11.25 19.688 L 8.438 19.688 L 8.438 5.625 L 11.25 5.625 L 11.25 19.688 Z"
          fill="currentColor"
        /> */}
        <text x="17" y="53" style={{ fontSize: '24px', fontWeight: '800' }} fill="currentColor">
          WvW
        </text>
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
